import lodash from 'lodash';
import {
  createRouter,
  createWebHashHistory
} from 'vue-router';
import config from '../../config.js';
const adminRole = 'Spindverwaltung:Administrator';
const managerRole = 'Spindverwaltung:Manager';
const workerRole = 'Spindverwaltung:Worker';
const routes = [
  {
    path: '/',
    alias: '/requests',
    name: 'main',
    component: () => import('@/views/Requests'),
    meta: {
      auth: true,
      roles: [adminRole, managerRole],
      navBarLink: '/',
      isMainForRoles: [adminRole, managerRole],
    },
  },
  {
    path: '/requests/details/:id',
    name: '/requests/details/:id',
    component: () => import('@/views/RequestsDetails'),
    meta: {
      auth: true,
      roles: [adminRole, managerRole],
    },
  },
  {
    path: '/requests/create',
    name: '/requests/create',
    component: () => import('@/views/RequestsCreate'),
    meta: {
      auth: true,
      roles: [adminRole, managerRole, workerRole],
      navBarLink: '/requests/create',
      redirectAfterAction: [
        {
          role: adminRole,
          name: 'main'
        },
        {
          role: managerRole,
          name: 'main'
        },
        {
          role: workerRole,
          name: '/rentals/check'
        }
      ]
    },
  },
  {
    path: '/lockers',
    name: '/lockers',
    component: () => import('@/views/Lockers'),
    meta: {
      auth: true,
      roles: [adminRole, managerRole],
      navBarLink: '/lockers',
    },
  },
  {
    path: '/lockers/details/:id',
    name: '/lockers/details/:id',
    component: () => import('@/views/LockersDetails'),
    meta: {
      auth: true,
      roles: [adminRole, managerRole]
    },
  },
  {
    path: '/lockers/create',
    name: '/lockers/create',
    component: () => import('@/views/LockersCreate'),
    meta: {
      auth: true,
      roles: [adminRole, managerRole]
    },
  },
  {
    path: '/lockers/free',
    name: '/lockers/free',
    component: () => import('@/views/LockersFree'),
    meta: {
      auth: true,
      roles: [adminRole, managerRole],
      navBarLink: '/lockers/free',
    },
  },
  {
    path: '/rentals',
    name: '/rentals',
    component: () => import('@/views/Rentals'),
    meta: {
      auth: true,
      roles: [adminRole, managerRole],
      navBarLink: '/lockers/free',
    },
  },
  {
    path: '/rentals/check',
    name: '/rentals/check',
    component: () => import('@/views/RentalsCheck'),
    meta: {
      auth: true,
      roles: [adminRole, managerRole, workerRole],
      navBarLink: 'rentals/check',
      isMainForRoles: [workerRole]
    },
  },
  {
    path: '/rentals/details/:id',
    name: '/rentals/details/:id',
    component: () => import('@/views/RentalsDetails'),
    meta: { auth: true, roles: [adminRole, managerRole] },
  },

  {
    path: '/lockers/summary',
    name: '/lockers/summary',
    component: () => import('@/views/LockersSummary'),
    meta: { auth: true, roles: [adminRole, managerRole], navBarLink: '/lockers/summary' },
  },
  {
    path: '/forbidden',
    name: 'error:401',
    component: () => import('@/views/ErrorPage'),
    props: {
      statusCode: 401,
      title: 'Seite wurde nicht gefunden',
      description: 'Eine solche Seite existiert nicht',
      isHomePageButton: true,
    },
    meta: {
      auth: false,
    },
  },
  {
    path: '/:pathMatch(.*)',
    name: 'error:404',
    component: () => import('@/views/ErrorPage'),
    props: {
      statusCode: 404,
      title: 'Seite wurde nicht gefunden',
      description: 'Eine solche Seite existiert nicht',
      isHomePageButton: true,
    },
    meta: {
      auth: false,
    },
  },
];

export function isRouteGranted(routeObjectOrName, store) {
  if (config.isAuthEnabled) {
    const route = lodash.isString(routeObjectOrName)
      ? routes.find(route => route.name == routeObjectOrName)
      : routeObjectOrName;


    const routeRoles = lodash.get(route, 'meta.roles', []);
    const userRoles = getUserRoles(store);
    const isRouteGranted =
      !routeRoles.length || routeRoles.find(role => userRoles.includes(role));

    return isRouteGranted;
  } else {
    return true;
  }
}

export function redirectToMain(router, store) {
  if (config.isAuthEnabled) {
    const userRoles = getUserRoles(store);
    const mainRoute = getMainRoute(userRoles);

    router.push({ name: mainRoute.name });
  } else {
    router.push('/');
  }
}

export function redirectAfterAction(router, store) {
  debugger;
  const currentRouteName = router.currentRoute._value.name;
  const route = routes.find(x => x.name == currentRouteName);
  const userRole = getUserRoles(store)[0];
  const redirect = route.meta.redirectAfterAction?.find(x => x.role == userRole);

  if (redirect) {
    router.push({ name: redirect.name });
  }
}

export function getRoutes(store) {
  if (config.isAuthEnabled) {
    const userRoles = getUserRoles(store);

    return routes.filter(route => {

      const roles = lodash.get(route, 'meta.roles', []);

      return !roles.length || roles.find(role => userRoles.includes(role));
    });
  } else {
    return routes;
  }
}

export function getMainRoute(userRoles) {
  if (config.isAuthEnabled) {
    const grantedRoutes = routes.filter(route => {
      const isMainForRoles = lodash.get(route, 'meta.isMainForRoles', []);

      return userRoles.find(role => isMainForRoles.includes(role)) != null;
    });

    return grantedRoutes[0];
  } else {
    routes.find(route => route.path === '/');
  }
}

export default function (store) {
  return createRouter({
    routes: routes,
    history: createWebHashHistory(),
  });
}

function getUserRoles(store) {
  return store.state.authInfo.roles;
}

//TODO: add debtorId to user
//TODO: hide navbar items
//TODO: use firstName, lastName, debtorId for creation form
//TODO: render 401 fo forbidden pages
//TODO: add logout
