<template>
  <div>
    <Calendar hideOnRangeSelection hideOnDateTimeSelect
      :inputClass="`calendar ${!isValid ? 'error' : ''} ${disabled ? 'disabled' : ''}`" class="wrapper"
      :modelValue="value" @update:modelValue="$emit('update:modelValue', $event.toISOString())" :disabled="disabled"
      dateFormat="dd.mm.yy" mask="99.99.9999" :selectionMode="selectionMode" :manualInput="manualInput" :showIcon="true"
      :readonly="false" :showOnFocus="false">
    </Calendar>
    <div class="v-input__details">
      <div v-if="!isValid" class="v-messages">
        <div class="v-messages__message">{{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from 'primevue/calendar';

import moment from 'moment';
import 'vue-select/dist/vue-select.css';


export default {
  name: 'UiVueCalendar',

  components: {
    Calendar
  },
  props: {
    selectionMode: {
      type: String,
      default: 'single'
    },
    modelValue: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    },
    error: {
      type: String,
      required: false
    },
    manualInput: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      value: this.formatDate(this.modelValue)
    };
  },
  methods: {
    formatDate: (value) => {
      if (value) {
        return moment(value).format('DD.MM.yyyy');
      }

      return value;
    }
  },
  watch: {
    'modelValue': function (value) {
      this.value = this.formatDate(value);
    }
  }
};
</script>
<style >
.calendar {
  border-radius: 0 !important;
  padding: 15px !important;
  font-family: "Roboto Condensed", sans-serif !important;
  border: 1px solid rgb(180, 180, 180) !important;
  color: #000 !important;
}

.calendar:hover,
.calendar:focus {
  border: 1px solid rgb(118, 118, 118) !important;
}

.error,
.error:hover,
.error:focus {
  border: 1px solid #b00020 !important;
}

.disabled,
.disabled:hover,
.disabled:focus {
  background-color: rgb(243, 243, 243) !important;
  border: 1px solid rgb(180, 180, 180) !important;
  cursor: not-allowed;
}
</style>