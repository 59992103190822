const config = {
  baseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:5322/api/' : '/api/',
  healthCheckUrl: '/healthcheck',
  healthCheckInterval: 3000,
  delay: 500,
  getDefaultHeaders() {
    return {
      Authorization: 'bearer ' + localStorage.getItem('token'),
    };
  },
  clients: {},
};

const formDataContentType = 'application/json';
const entityTypes = [
  'rental',
  'rentalRequest',
  'rentalObject',
  'building',
  'debtor',
  'rentalObjectKey',
];

for (const entityType of entityTypes) {
  config.clients[entityType] = {
    create: {
      url: '/create',
      method: 'post',
      headers: {
        'Content-Type': formDataContentType,
      },
    },
    update: {
      url: '/update',
      method: 'put',
      headers: {
        'Content-Type': formDataContentType,
      },
    },
    delete: {
      url: '/delete',
      method: 'delete',
    },
    query: {
      url: '/query',
      method: 'get',
    },
    excel: {
      url: '/export/excel',
      method: 'get',
      responseType: 'blob',
    },
    pdf: {
      url: '/export/pdf',
      method: 'get',
      responseType: 'blob',
    },
  };
}

config.clients['rentalRequest']['addComments'] = {
  url: '/addComments',
  method: 'post',
  headers: {
    'Content-Type': formDataContentType,
  },
};

config.clients['rentalRequest']['createRental'] = {
  url: '/createRental',
  method: 'post',
  headers: {
    'Content-Type': formDataContentType,
  },
};

config.clients['rental']['complete'] = {
  url: '/complete',
  method: 'post',
  headers: {
    'Content-Type': formDataContentType,
  },
};

config.clients['rental']['check'] = {
  url: '/check',
  method: 'get',
  headers: {
    'Content-Type': formDataContentType,
  },
};

config.clients['building']['getRoomSvgMap'] = {
  url: '/GetRoomSvgMap',
  method: 'get',
  responseType: 'blob',
};

export default config;
export const reportConfigs = {
  roomUsageReport: {
    url: '/roomUsageReport',
    method: 'get',
    responseType: 'blob',
  },
  buildingUsageReport: {
    url: '/buildingUsageReport',
    method: 'get',
    responseType: 'blob',
  },
  debtorReport: {
    url: '/debtorReport',
    method: 'get',
    responseType: 'blob',
  },
  debtorsReport: {
    url: '/debtorsReport',
    method: 'get',
    responseType: 'blob',
  },
};
